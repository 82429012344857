import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import moment from 'moment';

import {
  Address,
  Attachment,
  GenericFormGroup,
  GuestApplication,
  PropertySearcherDocumentTypes,
  UserProfileAdditionalInformation,
  Profession,
  HousingPermission,
  ProfileDKOne,
  ProfileDKThree,
  ProfileDKTwo,
  ProfileDKZero,
  GlobalCustomQuestions,
  CustomerLocation
} from '@ui/shared/models';
import {
  maxDateValidator,
  minDateValidator,
  zipCodeValidator
} from 'libs/components/legacy/form';

import { CheckApplication, GuestFormValues } from 'tenant-pool/auth/models';

@Injectable()
export class GuestFormService {
  private readonly form: GenericFormGroup<GuestFormValues>;

  constructor(private fb: FormBuilder) {
    this.form = this.createGuestForm();
  }

  public getForm() {
    return this.form;
  }

  public patchForm(application: GuestApplication) {
    const { userProfile } = application;
    const { email, data, address } = userProfile;
    const {
      profession,
      additionalInformation,
      firstname,
      name,
      dateOfBirth,
      householdType,
      residents,
      phone,
      furtherInformation,
      guarantorExist,
      attachments,
      portrait,
      moveInDate
    } = data;
    const { animals, housingPermission } =
      additionalInformation || ({} as UserProfileAdditionalInformation);
    const { wbsDocument, incomeProof, creditScore, otherDocuments } =
      this.getDocuments(attachments);

    const patchData = {
      checkApplication: {
        email
      },
      dkZero: {
        firstname,
        name,
        housingPermission,
        wbsDocument
      },
      dkOne: {
        portrait,
        address: address || {},
        phone
      },
      dkTwo: {
        residents,
        householdType,
        dateOfBirth: dateOfBirth
          ? moment(dateOfBirth).format('YYYY-MM-DD')
          : null,
        profession: profession || {},
        moveInDate,
        animals,
        guarantorExist,
        furtherInformation
      },
      dkThree: {
        incomeProof,
        creditScore,
        otherDocuments
      }
    };
    this.form.patchValue(patchData);
  }

  private createGuestForm() {
    const minDateOfBirth = moment('1900-01-01', 'YYYY-MM-DD').toDate();

    const checkApplication: GenericFormGroup<CheckApplication> = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])]
    }) as GenericFormGroup<CheckApplication>;

    const globalCustomQuestions: GenericFormGroup<GlobalCustomQuestions> =
      this.fb.group({
        customQuestionResponses: [],
        hierarchicalRootQuestions: []
      }) as GenericFormGroup<GlobalCustomQuestions>;

    const profession: GenericFormGroup<Profession> = this.fb.group({
      type: [undefined],
      subType: [undefined],
      income: [0]
    });

    const address: GenericFormGroup<Address> = this.fb.group({
      city: [null],
      zipCode: [
        null,
        {
          validators: zipCodeValidator,
          updateOn: 'blur'
        }
      ],
      street: [null],
      houseNumber: [null],
      district: [null],
      region: [null],
      country: [CustomerLocation.DE]
    });

    const housingPermission: GenericFormGroup<HousingPermission> =
      this.fb.group({
        type: [null],
        amountPeople: [null]
      });

    const dkZero: GenericFormGroup<ProfileDKZero> = this.fb.group({
      firstname: [''],
      name: [''],
      housingPermission,
      wbsDocument: [null]
    }) as GenericFormGroup<ProfileDKZero>;

    const dkOne: GenericFormGroup<ProfileDKOne> = this.fb.group({
      portrait: [null],
      address,
      phone: ['']
    }) as GenericFormGroup<ProfileDKOne>;

    const dkTwo: GenericFormGroup<ProfileDKTwo> = this.fb.group({
      householdType: [null],
      residents: [1],
      moveInDate: [''],
      dateOfBirth: [
        null,
        Validators.compose([
          maxDateValidator(moment().toDate()),
          minDateValidator(minDateOfBirth)
        ])
      ],
      profession,
      animals: [undefined],
      guarantorExist: [undefined],
      furtherInformation: [undefined]
    }) as GenericFormGroup<ProfileDKTwo>;

    const dkThree: GenericFormGroup<ProfileDKThree> = this.fb.group({
      creditScore: [null],
      incomeProof: [[]],
      otherDocuments: [[]]
    }) as GenericFormGroup<ProfileDKThree>;

    const review: GenericFormGroup = this.fb.group({});

    return this.fb.group({
      checkApplication,
      globalCustomQuestions,
      dkZero,
      dkOne,
      dkTwo,
      dkThree,
      review
    });
  }

  private getDocuments(attachments: Attachment[]) {
    return {
      incomeProof: attachments.filter(
        attachment =>
          attachment.type === PropertySearcherDocumentTypes.INCOME_STATEMENT
      ),
      creditScore: attachments.find(
        attachment =>
          attachment.type === PropertySearcherDocumentTypes.CREDIT_REPORT
      ),
      wbsDocument: attachments.find(
        attachment =>
          attachment.type === PropertySearcherDocumentTypes.WB_CERTIFICATE
      ),
      otherDocuments: attachments.filter(
        attachment =>
          attachment.type !== PropertySearcherDocumentTypes.INCOME_STATEMENT &&
          attachment.type !== PropertySearcherDocumentTypes.CREDIT_REPORT &&
          attachment.type !== PropertySearcherDocumentTypes.WB_CERTIFICATE
      )
    };
  }
}
